<template>
  <form ref="form" @submit.prevent="handleValidate">
    <b-row>
      <b-col>
        <basic-select
          label="Chọn nhóm món ăn"
          name="status"
          placeholder="--- Chọn nhóm món ăn ---"
          :options="foodCategories"
          :value.sync="form.foodCategory"
          @update:value="selectFoodCategory"
        />
      </b-col>
      <b-col>
        <basic-select
          label="Chọn món ăn"
          name="food"
          placeholder="--- Chọn món ăn ---"
          :required="true"
          :options="foods"
          :value.sync="form.food"
          :state="validateState('food')"
          :invalidFeedback="errors.first('food')"
          v-validate="'required'"
          data-vv-as="Chọn món ăn"
          @update:value="selectFood"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row>
          <b-col class="d-flex">
            <basic-input
              label="Khẩu phần"
              description
              placeholder="X.Y"
              :value.sync="form.portion"
              name="portion"
              :required="true"
              :state="validateState('portion')"
              :invalidFeedback="errors.first('portion')"
              v-validate="{
                required: true,
                regex: REGEX.NUMBER_DECIMAL,
                max: 5,
                more_than_zero: true,
              }"
              data-vv-as="Khẩu phần"
              maxlength="5"
              @v-modal:update="handleInputPortion"
            ></basic-input>
            <div
              v-if="form.unit"
              class="d-flex justify-content-center align-items-center ml-4"
            >
              <span>{{ form.unit }}</span>
            </div>
          </b-col>
          <b-col>
            <basic-input
              label="Kcal"
              description
              :value.sync="form.calorie"
              name="calorie"
              disabled
            ></basic-input>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <basic-input
              label="Đường (g)"
              description
              :value.sync="form.glucose"
              name="glucose"
              disabled
            ></basic-input>
          </b-col>
          <b-col>
            <basic-input
              label="Đạm (g)"
              description
              :value.sync="form.protein"
              name="protein"
              disabled
            ></basic-input>
          </b-col>
          <b-col>
            <basic-input
              label="Béo (g)"
              description
              :value.sync="form.lipid"
              name="lipid"
              disabled
            ></basic-input>
          </b-col>
          <b-col>
            <basic-input
              label="Xơ (g)"
              description
              :value.sync="form.fibre"
              name="fibre"
              disabled
            ></basic-input>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <basic-input
          label="Chú thích"
          description
          placeholder="Chí thích"
          :value.sync="form.note"
          name="note"
          maxlength="30"
        ></basic-input>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-end">
      <b-button
        class="btn btn-plain ml-3"
        type="button"
        @click.stop="handleCancel"
      >
        Huỷ
      </b-button>
      <b-button class="btn btn-success ml-3" type="submit">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
        </span>
        Lưu
      </b-button>
    </div>
  </form>
</template>
<script>
import { round } from 'lodash';
import { REGEX } from '@/core/plugins/constants';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('menu');
export default {
  name: 'FormFood',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'detail',
    },
    foodId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        portion: null,
        glucose: null,
        calorie: null,
        protein: null,
        foodCategory: null,
        lipid: null,
        fibre: null,
        note: null,
        food: null,
        unit: null,
      },
      foodCategories: [],
      foods: [],
      REGEX,
    };
  },
  computed: {
    ...mapState(['bodyCreateFoodTemplate']),
    isUpdate() {
      return this.type === 'edit' || false;
    },
  },
  watch: {
    data: {
      handler(val) {
        if (!val) return;
        this.setupData();
      },
      deep: true,
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      await Promise.all([this.getFoodByCategory(), this.getFoodCategories()]);
      this.setupData();
    },
    setupData() {
      if (this.data) {
        this.form = { ...this.form, ...this.data };
        this.form.food = this.foods.find((el) => el.id === this.form.foodId);
        this.form.foodCategory = this.foodCategories.find(
          (el) => el.id === this.form.foodCategoryId,
        );
        this.form.unit = this.form.foodUnitName;
        this.form.calorie = round(this.form.calorie * this.form.portion, 1);
        this.form.glucose = round(this.form.glucose * this.form.portion, 1);
        this.form.protein = round(this.form.protein * this.form.portion, 1);
        this.form.lipid = round(this.form.lipid * this.form.portion, 1);
        this.form.fibre = round(this.form.fibre * this.form.portion, 1);
      }
    },
    async submit() {
      let params = {
        foodId: this.form.food.id,
        portion: Number(this.form.portion),
        note: this.form.note,
      };
      if (!this.data) {
        params = {
          ...params,
          ...this.bodyCreateFoodTemplate,
        };
      } else {
        params = {
          ...params,
          id: this.data.id,
        };
        if (this.bodyCreateFoodTemplate?.parentId) {
          params['parentId'] = this.bodyCreateFoodTemplate.parentId;
        }
        if (this.bodyCreateFoodTemplate?.foodType) {
          params['foodType'] = this.bodyCreateFoodTemplate.foodType;
        }
      }
      this.$emit('submit', params);
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit();
        }
        return;
      });
    },
    handleCancel() {
      this.$emit('cancel');
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    async getFoodCategories() {
      try {
        const params = {
          page: 1,
          size: 1000,
        };
        if (this.foodId) {
          params['foodId'] = this.foodId;
        }
        const { data } = await this.$api.get('/Food/Category', { params });
        this.foodCategories = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    selectFoodCategory(value) {
      if (value) {
        this.form.foodCategory = this.foodCategories.find(
          (el) => el.id === value,
        );
      }
      if (this.form.food) {
        this.form.food = null;
        this.form.calorie = null;
        this.form.glucose = null;
        this.form.protein = null;
        this.form.lipid = null;
        this.form.fibre = null;
        this.form.unit = null;
      }
      this.getFoodByCategory();
    },
    async getFoodByCategory() {
      try {
        const params = {
          page: 1,
          size: 1000,
        };
        if (this.form.foodCategory) {
          params['foodCategoryId'] = this.form.foodCategory.id;
        }
        if (this.foodId) {
          params['foodId'] = this.foodId;
        }
        const { data } = await this.$api.get('/Food', { params });
        this.foods = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    selectFood(value) {
      if (!value) {
        this.form.calorie = null;
        this.form.glucose = null;
        this.form.protein = null;
        this.form.lipid = null;
        this.form.fibre = null;
        this.form.unit = null;
        return;
      }
      const foodSelected = this.foods.find((el) => el.id === value);
      this.form.food = foodSelected;
      if (this.form.portion) {
        this.form.calorie = round(foodSelected.calorie * this.form.portion, 1);
        this.form.glucose = round(foodSelected.glucose * this.form.portion, 1);
        this.form.protein = round(foodSelected.protein * this.form.portion, 1);
        this.form.lipid = round(foodSelected.lipid * this.form.portion, 1);
        this.form.fibre = round(foodSelected.fibre * this.form.portion, 1);
      } else {
        this.form.calorie = foodSelected.calorie;
        this.form.glucose = foodSelected.glucose;
        this.form.protein = foodSelected.protein;
        this.form.lipid = foodSelected.lipid;
        this.form.fibre = foodSelected.fibre;
      }
      this.form.unit = foodSelected.unit;
    },
    handleInputPortion(value) {
      if (!value) {
        this.form.calorie = this.form.food?.calorie || null;
        this.form.glucose = this.form.food?.glucose || null;
        this.form.protein = this.form.food?.protein || null;
        this.form.lipid = this.form.food?.lipid || null;
        this.form.fibre = this.form.food?.fibre || null;
        return;
      }
      this.form.portion = round(value, 1);
      if (this.form.food) {
        this.form.calorie = round(
          this.form.food.calorie * this.form.portion,
          1,
        );
        this.form.glucose = round(
          this.form.food.glucose * this.form.portion,
          1,
        );
        this.form.protein = round(
          this.form.food.protein * this.form.portion,
          1,
        );
        this.form.lipid = round(this.form.food.lipid * this.form.portion, 1);
        this.form.fibre = round(this.form.food.fibre * this.form.portion, 1);
      }
    },
  },
};
</script>
